import { useState } from "react";
import { ImmutableSet } from "../helpers/immutable-set";

export function useCollectionSetState<T>(initial?: Set<T>): [Set<T>, {add: (el: T) => void, delete: (el: T) => void}] {
  let [state, setState] = useState(initial ? new ImmutableSet(initial) : new ImmutableSet<T>())

  let update = {
    add: (el: T) => setState(prev => prev.immutableAdd(el)),
    delete: (el: T) => setState(prev => prev.immutableDelete(el))
  }

  return [state, update]
}

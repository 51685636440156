import { useEffect, useState } from "react"

export let MINUTE_IN_MS = 60000
export let HOUR_IN_MS = MINUTE_IN_MS * 60

export function useTime(interval = MINUTE_IN_MS): [number] {
  let [now, setNow] = useState(Date.now())

  useEffect(() => {
    let id = setInterval(() => setNow(Date.now()), interval)
    return () => clearInterval(id)
  }, [interval])

  return [now]
}

import { useSearchParams } from "react-router-dom"
import { DEFAULT_FILTERS, Filters } from "../data/courses"

type UrlParamValue = string|boolean|number

export function formatSearchParam(searchParams: URLSearchParams, key: string, value: UrlParamValue) {
  let nextParams = {...Object.fromEntries(searchParams.entries())}
  let usefulDefaults = DEFAULT_FILTERS as Record<string, UrlParamValue>

  switch (typeof value) {
    case 'string':
      value ? nextParams[key] = value : delete nextParams[key]
      break
    case 'number':
    case 'boolean':
      usefulDefaults[key] === value ? delete nextParams[key] : nextParams[key] = value.toString()
      break
  }

  return new URLSearchParams(nextParams)
}

type KeyValue = [key: string, value: UrlParamValue]
export function useSimpleSearchParams(): [URLSearchParams, (params: KeyValue[]) => void, () => void] {
  let [searchParams, _setSearchParams] = useSearchParams()

  let setSearchParams = (params: KeyValue[]) => {
    let nextParams = params.reduce((memo, [key, value]) => {
      return formatSearchParam(memo, key, value)
    }, searchParams)

    _setSearchParams(nextParams)
  }

  return [searchParams, setSearchParams, () => _setSearchParams({})]
}

export function getAsBoolean(params: URLSearchParams, key: keyof Filters) {
  switch (params.get(key)) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}

export function getAsInt(params: URLSearchParams, key: string, fallback: number): number {
  let value = params.get(key)
  if (value == null) {
    return fallback
  }

  return parseInt(value)
}

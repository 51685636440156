import GoogleMapReact from 'google-map-react'
import { useRef } from 'react'
import Supercluster from 'supercluster'
import { Row } from '../data/db'
import { ClusterMarker } from './ClusterMarker'
import { CourseMarker } from './CourseMarker'
import useGeolocation from 'react-hook-geolocation'
import { CurrentLocationMarker } from './CurrentLocationMarker'
import { UpdateAndRefresh } from '../hooks/use-courses'


type Props = {
  zoom: number
  defaultZoom: number
  defaultCenter: GoogleMapReact.Coords
  setZoom: (zoom: number) => void
  setBounds: React.Dispatch<React.SetStateAction<[number, number, number, number] | undefined>>
  setBoundsParam: React.Dispatch<React.SetStateAction<GoogleMapReact.Bounds|undefined>>
  showNames: boolean
  supercluster: Parameters<typeof ClusterMarker>[0]['supercluster']
  updateCourse: UpdateAndRefresh
  clusters: (Supercluster.PointFeature<any> | Supercluster.PointFeature<{
    cluster: false;
    id: string;
    course: Row;
  }>)[]
  points: Supercluster.PointFeature<{
    cluster: false;
    id: string;
    course: Row;
  }>[]
}
export function GoogleMap(props: Props) {
  const mapRef = useRef();
  let geoLocation = useGeolocation()
  let maybeMePoint = geoLocation.error ? null : {
    lat: geoLocation.latitude,
    lon: geoLocation.longitude
  }

  return <GoogleMapReact
    onChange={({ zoom, bounds }) => {
      props.setBoundsParam(bounds)
      props.setZoom(zoom);
      props.setBounds([
        bounds.nw.lng,
        bounds.se.lat,
        bounds.se.lng,
        bounds.nw.lat
      ]);
    }}
    onGoogleApiLoaded={({ map }) => {
      mapRef.current = map;
    }}
    yesIWantToUseGoogleMapApiInternals
    bootstrapURLKeys={{ key: 'AIzaSyAehVxAU84pmuxRHOc8esqc35ky1Ig-DLE' }}
    defaultCenter={props.defaultCenter}
    defaultZoom={props.defaultZoom}
  >

    {maybeMePoint && <CurrentLocationMarker zoom={props.zoom} lat={maybeMePoint.lat} lng={maybeMePoint.lon} />}

    {props.clusters.map(cluster => {
      const [lng, lat] = cluster.geometry.coordinates

      if (cluster.properties.cluster) {
        return <ClusterMarker
          key={`cluster-${cluster.id}`}
          pointCount={cluster.properties.point_count}
          cluster={cluster}
          supercluster={props.supercluster}
          lat={lat}
          lng={lng}
          mapRef={mapRef}
          points={props.points}
          zoom={props.zoom}
        />
      }

      return <CourseMarker
        key={`course-${cluster.properties.course._id}`}
        lat={lat}
        lng={lng}
        cluster={cluster}
        showNames={props.showNames}
        zoom={props.zoom}
        updateCourse={props.updateCourse}
      />
    }
  )}

  {/* {schedule.map((game, i) => <SoccerMarker lat={game.lat} lng={game.lon} zoom={zoom} />)} */}
  </GoogleMapReact>
}

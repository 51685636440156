import getData, {Row} from './db'

export const RANKING_LEVELS = [
  {label: 'World', rank: 1},
  {label: 'Continent', rank: 2},
  {label: 'Country', rank: 3},
  {label: 'Region', rank: 4},  
]

export async function getAll() {
  let rows = await getData()
  return rows.sort((a, b) => {
    if (isInPlan(a) && !isInPlan(b)) {
      return 1
    }

    if (isInPlan(b) && !isInPlan(a)) {
      return -1
    }

    if (a.confidentialGuide && !b.confidentialGuide) {
      return 1
    }

    if (b.confidentialGuide && !a.confidentialGuide) {
      return -1
    }

    if (a.confidentialGuide && b.confidentialGuide) {
      return a.confidentialGuide.score - b.confidentialGuide.score
    }

    if (!a.rankings && !b.rankings) {
      return 0
    }

    if (a.rankings && b.rankings) {
      return getComparableRank(b) - getComparableRank(a)
    }

    return 0
  })
}

function getComparableRank(row: Row): number {
  if (!row.rankings) {
    return Number.MAX_SAFE_INTEGER
  }

  return row.rankings.reduce((memo, ranking) => {
    let score = ranking.level * ranking.rank

    return Math.min(score, memo)
  }, Number.MAX_SAFE_INTEGER)
}

export async function getTotal() {
  let rows = await getAll()
  return rows.length
}

export function isInPlan(course: Row): boolean {
  return !!(course.recommenders?.length || course.landmark)
}

export type Filters = {
  recommendedBy?: string
  landmarks?: boolean
  courseNameSearch?: string
  confidentialGuideScore?: number
  rankingLevel?: number
  ranking?: number
  plan?: boolean
}

export const DEFAULT_FILTERS = {
  confidentialGuideScore: 7,
  rankingLevel: 2,
  ranking: 100,
  plan: false,
} as const

export type GeoPoint = {
  type: 'Feature'
  properties: {
    cluster: false
    id: string
    course: Row
  }
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
}

export async function getGeoPoints(filters: Filters = {}): Promise<GeoPoint[]> {
  let rows = await getAll()

  return getGeoPointsForRows(rows, filters)
}

function getGeoPointsForRows(rows: Row[], filters: Filters = {}): GeoPoint[] {
  return rows
    .filter(course => {
      if (Object.keys(filters).length === 0) {
        return true
      }

      if (filters.plan) {
        return isInPlan(course)
      }

      if (filters.recommendedBy) {
        return course.recommenders?.includes(filters.recommendedBy)
      }

      if (filters.landmarks) {
        return !!course.landmark
      }

      if (filters.courseNameSearch) {
        if (filters.courseNameSearch.startsWith('/')) {
          let regex: RegExp
          // handle partial regexes that are invalid
          try {
            regex = new RegExp(filters.courseNameSearch.replace(/^\/(.*?)\/?$/, (match, capture) => capture), 'i')
          } catch (e) {
            return false
          }
          return course.name.toLowerCase().match(regex)
        }

        return course.name.toLowerCase().includes(filters.courseNameSearch.toLowerCase())
      }

      let {confidentialGuideScore} = filters
      if (
        confidentialGuideScore !== undefined
        && course.confidentialGuide?.score
        && (confidentialGuideScore === 0 || course.confidentialGuide?.score >= confidentialGuideScore)
      ) {
        return true
      }

      let {rankingLevel, ranking} = filters
      if (ranking === 0) {
        return true
      }
      if (rankingLevel && ranking) {
        let matchesRankTarget = course.rankings?.find(courseRanking => {
          return courseRanking.level <= rankingLevel! && courseRanking.rank <= ranking!
        })

        if (matchesRankTarget) {
          return true
        }
      }

      return false
    })
    .map(course => {
      return {
        type: 'Feature',
        properties: {
          cluster: false,
          id: course._id,
          course,
        },
        geometry:  {
          type: 'Point',
          coordinates: [parseFloat(course.location.lon), parseFloat(course.location.lat)],
        }
      }
    })
}

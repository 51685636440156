export type Row = {
  _id: string
  name: string
  recommenders?: string[]
  landmark?: boolean
  rankings?: {rank: number, flag: string, title: string, url: string, level: number}[]
  website?: string
  visitorInfo?: string
  architect?: string[]
  phone?: string
  location: {lat: string, lon: string, address: string}
  url?: string
  regionSlug?: string
  confidentialGuide?: {score: number, page: number}
  scheduledOn?: Date
  media?: {
    instagram?: {
      title: string
      url: string
    }
  }
}

let cachedPromise: Promise<Row[]> | null = null

export default async function getData(): Promise<Row[]> {
  if (!cachedPromise) {
    refreshCache()
  }

  return cachedPromise!
}

export function refreshCache() {
  cachedPromise = fetch('https://uk-api.evansolomon.me/api/courses')
    .then(res => res.json())
    .then((res: {courses: Row[]}) => {
      return res.courses.map(course => {
        if (course.scheduledOn) {
          course.scheduledOn = new Date(course.scheduledOn)
        }

        return course
      })
    })
    .finally(() => cachedPromise = null)

  return cachedPromise
}

export class ImmutableSet<T> extends Set<T> {
  immutableAdd(el: T): ImmutableSet<T> {
    return new ImmutableSet(this).add(el)
  }

  immutableDelete(el: T): ImmutableSet<T> {
    let set =  new ImmutableSet(this)
    set.delete(el)
    return set
  }
}

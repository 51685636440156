import React, { useState } from 'react'
import { ListGroup } from 'react-bootstrap'
import Supercluster from 'supercluster'
import { isInPlan } from '../data/courses'
import { Row } from '../data/db'
import { formatCourseToTitle } from '../format/courseFormatter'
import GoogleMapReact from 'google-map-react'

export type SC = Supercluster<{
  cluster: false
  id: string
  course: Row
}, any> | undefined

function getColor(courses: Row[]): string {
  if (courses.some(course => course.scheduledOn)) {
    return 'radial-gradient(#615493, #dab5ce)'
  } else if (courses.some(course => isInPlan(course))) {
    return 'radial-gradient(#FF0000, #ffcccb)'
  } else {
    return 'radial-gradient(#888, #bbb)'
  }
}

export function ClusterMarker(props: {
  cluster: Supercluster.PointFeature<unknown>,
  pointCount: number,
  supercluster: SC,
  mapRef: React.MutableRefObject<any>,
  points: unknown[],
  zoom: number
} & GoogleMapReact.Coords) {
  let [tooltip, setTooltip] = useState(false)
  let [zIndex, setZIndex] = useState<number|undefined>(undefined)

  if (!props.supercluster) {
    return null
  }

  if (!props.cluster.id || typeof props.cluster.id !== 'number') {
    throw new Error('Bad cluster')
  }

  let size = 20 + ((props.pointCount * 4) / Math.max(props.points.length, 10)) * props.zoom * 10
  let courses = props.supercluster.getLeaves(props.cluster.id, props.pointCount).map(item => item.properties.course)
  let tooltipContent = tooltip
    ? (
      <>
        <span
          style={{
            position: 'absolute',
            display: 'block',
            bottom: `${size}px`,
            backgroundColor: 'lightgrey',
            borderRadius: '5px',
            fontSize: `16px`,
            width: '250px',
            zIndex: 1000
          }}>
            <ListGroup>
              {courses.map(c => <ListGroup.Item key={c._id}>{formatCourseToTitle(c)}</ListGroup.Item>)}
            </ListGroup>
          </span>
      </>
    )
    : null

  return (
    <div
      className="cluster-marker"
      style={{
        cursor: 'pointer',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size}px`,
        background: getColor(courses),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: `-${size/2}px`,
        left: `-${size/2}px`,
        zIndex,
      }}

      onMouseEnter={() => {
        setTooltip(true)
        setZIndex(10)
      }}
      onMouseLeave={() => {
        setTooltip(false)
        setZIndex(undefined)
      }}

      onClick={() => {
        if (!props.supercluster) {
          return
        }

        const expansionZoom = Math.min(
          props.supercluster.getClusterExpansionZoom(props.cluster.id as number),
          20
        );
        props.mapRef.current.setZoom(expansionZoom);
        props.mapRef.current.panTo({ lat: props.lat, lng: props.lng });
      }}
    >
      {tooltipContent}
      {props.pointCount}
    </div>
  )
}

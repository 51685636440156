import { Row } from '../data/db'

export function formatCourseToTitle(course: Row) {
  let title = course.name
  if (course.confidentialGuide) {
    title += ` (${course.confidentialGuide.score})`
  }

  return title
}

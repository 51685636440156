import { useState } from 'react'
import Supercluster from 'supercluster'
import { Row } from '../data/db'
import redPin from '../icons/red-pin.png'
import grayPin from '../icons/gray-pin.png'
import purplePin  from '../icons/purple-pin.png'
import { formatCourseToTitle } from '../format/courseFormatter'
import { isInPlan } from '../data/courses'
import { ListGroup } from 'react-bootstrap'
import GoogleMapReact from 'google-map-react'
import { UpdateAndRefresh } from '../hooks/use-courses'
import { useActivePromises } from '../hooks/use-active-promises'

export type CourseMarkerProps = {
  cluster: Supercluster.PointFeature<{cluster: false, id: string, course: Row}>
  showNames: boolean
  zoom: number
  updateCourse: UpdateAndRefresh
} & GoogleMapReact.Coords

function getPin(course: Row): string {
  if (course.scheduledOn) {
    return purplePin
  } else if (isInPlan(course)) {
    return redPin
  } else {
    return grayPin
  }
}

export function CourseMarker({ cluster, showNames, zoom, updateCourse }: CourseMarkerProps) {
  let course = cluster.properties.course
  let [hovered, setHovered] = useState(false);
  let [mouseInside, setMouseInside] = useState(false)
  let [zIndex, setZIndex] = useState<number | undefined>(undefined);
  let [hasActivePromises, trackPromise] = useActivePromises()
  let title = formatCourseToTitle(course);

  let mapPin = getPin(course)
  let showTooltip = hovered || showNames || hasActivePromises

  let size = 4 * zoom;
  let innerContent = course.url
    ? <>
      <p><a href={cluster.properties.course.url} target='_blank' rel='noreferrer'>{title}</a></p>
      {course.media?.instagram ? <a target='_blank' href={course.media.instagram.url}>Photos</a> : null}
    </>
    : title;

  let contentComponent = (
    <>
      {showTooltip
        ? <span
          style={{
            position: 'absolute',
            display: 'block',
            bottom: `${size}px`,
            fontSize: `${size / 2}px`,
            width: '200px',
            zIndex: 10,
          }}>
          <ListGroup>
            <ListGroup.Item>{innerContent}</ListGroup.Item>
          </ListGroup>
        </span>
        : null}
      <img
        src={mapPin}
        alt=""
        style={{
          width: `${size}px`,
          position: 'absolute',
          bottom: '0px',
          left: `-${size / 2}px`,
        }} />
    </>
  );

  return (
    <div
      style={{ zIndex }}

      onMouseEnter={() => {
        setMouseInside(true)
        setHovered(true)
        setZIndex(10)
      }}
      onMouseLeave={() => {
        setMouseInside(false)
        setHovered(false)
        setZIndex(undefined)
      }}

      onClick={() => {
        // only for mobile
        if (mouseInside) {
          return
        }

        setHovered(prev => !prev)
        setZIndex(prev => prev ? undefined : 10)
      }}
    >
      {contentComponent}
    </div>
  );
}

import GoogleMapReact from 'google-map-react'

type Props = {
  zoom: number
} & GoogleMapReact.Coords

export function CurrentLocationMarker(props: Props) {
  let size = 2 * props.zoom
  return (
    <div
      style={{
        background: 'rgba(19,135,212,.95)',
        display: 'block',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '100%'
      }}
    >
    </div>
  )
}

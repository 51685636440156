import { useEffect, useState } from 'react'
import {Filters, GeoPoint, getGeoPoints} from '../data/courses'
import { Row } from '../data/db'

function updateCourse(id: string, update: Record<string, unknown>) {
  return fetch(`https://uk-api.evansolomon.me/api/courses/${id}`, {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(update),
  }).catch(e => alert(e))
}

export type UpdateAndRefresh = (...args: Parameters<typeof updateCourse>) => Promise<unknown>

export function useCourseGeoPoints(filters: Filters = {}): [GeoPoint[], UpdateAndRefresh] {
  let [courseGeoPoints, setCourseGeoPoints] = useState<GeoPoint[]>([])

  async function refresh(): Promise<unknown> {
    return getGeoPoints(filters).then(setCourseGeoPoints)
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getGeoPoints(filters).then(setCourseGeoPoints)
  }, [JSON.stringify(filters)])
  /* eslint-enable react-hooks/exhaustive-deps */

  function updateAndRefresh(...args: Parameters<typeof updateCourse>) {
    return updateCourse(...args).then(refresh)
  }

  return [courseGeoPoints, updateAndRefresh]
}

export function useCourses(filters: Filters = {}): [Row[]] {
  let [courseGeoPoints] = useCourseGeoPoints()
  let courses = courseGeoPoints.map(geoPoint => geoPoint.properties.course)
  return [courses]
}

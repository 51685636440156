import React from 'react'
import {Button, Col, Form} from 'react-bootstrap'
import RangeSlider from 'react-bootstrap-range-slider'
import { DEFAULT_FILTERS, Filters, RANKING_LEVELS } from '../data/courses'
import { useSimpleSearchParams } from '../helpers/searchParams'
import GoogleMapReact from 'google-map-react'
import { Link } from 'react-router-dom'

type SidebarProps = {
  filters: Filters
  cluster: {radius: number, setRadius: (r: number) => void, count: number, setCount: (c: number) => void}
  displayCount: number
  totalCount: number
  showNames: boolean
  setShowNames: React.Dispatch<React.SetStateAction<boolean>>
  sidebarOpen: boolean
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  zoom: number
  bounds?: GoogleMapReact.Bounds
}

function isDefaultSearch(filters: Record<string, unknown>): boolean {
  let searchHasNonDefaultKeys = Object.entries(filters)
    .some(([key, value]) => {
      if (value === undefined) {
        return false
      }

      return (DEFAULT_FILTERS as Record<string, unknown>)[key] === undefined
    })

  if (searchHasNonDefaultKeys) {
    return false
  }

  let onlyHasDefaults = Object.entries(DEFAULT_FILTERS).every(([defaultKey, defaultValue]) => {
    return filters[defaultKey] === undefined || filters[defaultKey] === defaultValue
  })

  return onlyHasDefaults
}

function ClosedSidebar(props: SidebarProps) {
  return (
    <Button
      variant='secondary'
      style={{position: 'absolute', zIndex: 100, top: '10px', left: '10px'}}
      onClick={() => props.setSidebarOpen(true)}
    >
      {'\u203A\u203A'}
    </Button>
  )
}

function OpenSidebar(props: SidebarProps) {
  let [, setSearchParam, clearSearchParams] = useSimpleSearchParams()
  let hasDefaultSearch = isDefaultSearch(props.filters)

  let isNameActive = !props.filters.plan
  let isPlanActive = !props.filters.courseNameSearch
  let isRankingActive = !props.filters.plan && !props.filters.courseNameSearch

  let currentViewUrl = new URL(window.location.href)
  currentViewUrl.searchParams.set('zoom', props.zoom.toString())
  currentViewUrl.searchParams.set('bounds', JSON.stringify(props.bounds))

  return (
    <Col xs={8} sm={6} md={4} lg={2} className='left'>
    <div className="d-grid gap-2">
      <Button className='sidebar-toggle' variant='secondary' onClick={() => props.setSidebarOpen(false)}>
        {'\u2039\u2039'}
      </Button>

      <Button variant='warning' disabled={hasDefaultSearch} onClick={clearSearchParams}>Reset search</Button>
    </div>

    <hr/>
    <Form className={`sidebar-form ${!isPlanActive ? 'disabled' : ''}`}>
      <Form.Group>
        <Form.Label>
          Planned Courses
          <Form.Check
            className='inline-checkbox'
            checked={props.filters.plan}
            onChange={e => setSearchParam([['plan', e.target.checked]])}
          />
        </Form.Label>
      </Form.Group>
    </Form>

    <div className="strike"><span>OR</span></div>

    <Form className={`sidebar-form ${!isNameActive ? 'disabled' : ''}`}>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type='text'
          value={props.filters.courseNameSearch || ''}
          onChange={e => setSearchParam([['courseNameSearch', e.target.value]])}
        />
      </Form.Group>
    </Form>

    <div className="strike"><span>OR</span></div>

    <Form className={`sidebar-form ${!isRankingActive ? 'disabled' : ''}`}>
      <Form.Group>
        <Form.Label>Confidential Guide Score</Form.Label>

        <RangeSlider
          value={props.filters.confidentialGuideScore || 0}
          min={0}
          max={10}
          step={1}
          onChange={e => setSearchParam([['confidentialGuideScore', parseInt(e.target.value)]])}
          tooltip='auto'
          tooltipPlacement='top'
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Top 100 Golf Rank</Form.Label>
        {RANKING_LEVELS.map(({label, rank}) => {
          return (
            <div key={label}>
              <Form.Check
                type='radio'
                checked={props.filters.rankingLevel
                  ? props.filters.rankingLevel === rank
                  : label === 'Continent'
                }
                value={rank}
                label={label}
                onChange={e => setSearchParam([['rankingLevel', parseInt(e.target.value)]])}
              />
            </div>
          )
        })}

        <RangeSlider
          value={props.filters.ranking || 0}
          min={0}
          max={100}
          step={5}
          onChange={e => setSearchParam([['ranking', parseInt(e.target.value)]])}
        />
      </Form.Group>
    </Form>

    <div className="strike"><span>Display</span></div>

    <Form className='sidebar-form'>
      <Form.Group>
        <Form.Label>Cluster Radius</Form.Label>
        <RangeSlider
          value={props.cluster.radius}
          min={0}
          max={200}
          step={5}
          onChange={e => props.cluster.setRadius(parseInt(e.target.value))}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Min Cluster Size</Form.Label>
        <RangeSlider
          value={props.cluster.count}
          min={0}
          max={5}
          step={1}
          onChange={e => props.cluster.setCount(parseInt(e.target.value))}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>
          Show Names
          <Form.Check
            className='inline-checkbox'
            checked={props.showNames}
            onChange={e => props.setShowNames(e.target.checked)}
          />
        </Form.Label>
      </Form.Group>

    </Form>

    <div className='display-status'>
      Showing {props.displayCount} of {props.totalCount}
    </div>

    <div className='display-status'>
      <a href={currentViewUrl.toString()}>Current view</a>
    </div>

    <div className='display-status'>
      <Link to='/stats'>Stats</Link>
    </div>
    </Col>
  )
}

export function Sidebar(props: SidebarProps) {
  return props.sidebarOpen
    ? <OpenSidebar {...props} />
    : <ClosedSidebar {...props} />
}

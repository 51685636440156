import { useCollectionSetState } from "./use-collection-state"

export function useActivePromises(): [boolean, (promise: Promise<unknown>) => void] {
  let [promises, updatePromises] = useCollectionSetState<Promise<unknown>>()

  function trackPromise(promise: Promise<unknown>) {
    updatePromises.add(promise)
    promise.finally(() => updatePromises.delete(promise))
  }

  return [promises.size > 0, trackPromise]
}

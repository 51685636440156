import { useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import Supercluster from 'supercluster'
import useSupercluster from 'use-supercluster'
import { Filters, DEFAULT_FILTERS } from './data/courses'
import {GoogleMap} from './components/GoogleMap'
import { Sidebar } from './components/Sidebar'
import { Col, Container } from 'react-bootstrap'
import { useWindowWidth } from '@react-hook/window-size'
import { getAsBoolean, getAsInt, useSimpleSearchParams } from './helpers/searchParams'
import GoogleMapReact from 'google-map-react'
import { useCourseGeoPoints } from './hooks/use-courses'
import { StatsPage } from './components/StatsPage'


function App() {
  const [bounds, setBounds] = useState<[number, number, number, number]>();
  const [zoom, setZoom] = useState(10)
  const [clusterRadius, setClusterRadius] = useState(75)
  const [clusterCount, setClusterCount] = useState(2)
  const [showNames, setShowNames] = useState(false)
  const [urlBoundsParam, setUrlBoundsParam] = useState<GoogleMapReact.Bounds|undefined>()
  let windowWidth = useWindowWidth()
  const [sidebarOpen, setSidebarOpen] = useState(windowWidth > 800)
  let [searchParams, setSearchParams] = useSimpleSearchParams()

  let filters: Filters = useMemo(() => {
    return {
      confidentialGuideScore: getAsInt(searchParams, 'confidentialGuideScore', DEFAULT_FILTERS.confidentialGuideScore),
      ranking: getAsInt(searchParams, 'ranking', DEFAULT_FILTERS.ranking),
      rankingLevel: getAsInt(searchParams, 'rankingLevel', DEFAULT_FILTERS.rankingLevel),
      courseNameSearch: searchParams.get('courseNameSearch') || undefined,
      plan: getAsBoolean(searchParams, 'plan') ?? DEFAULT_FILTERS.plan,
      recommendedBy: searchParams.get('recommendedBy') || undefined,
    }
  }, [searchParams])

  let boundsParam = JSON.parse(searchParams.get('bounds')!) as {
    sw: GoogleMapReact.Coords,
    nw: GoogleMapReact.Coords,
    se: GoogleMapReact.Coords,
    ne: GoogleMapReact.Coords
  }|undefined
  let mapSettings = {
    defaultZoom: getAsInt(searchParams, 'zoom', 6),
    defaultCenter: boundsParam
      ? {
        lng: (boundsParam.sw.lng + boundsParam.se.lng) / 2,
        lat: (boundsParam.nw.lat + boundsParam.se.lat) / 2,
      }
      : {lat: 54, lng: -3}
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSearchParams([['zoom', ''], ['bounds', '']])
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  let [points, updateCourse] = useCourseGeoPoints(filters)

  let {clusters, supercluster} = useSupercluster({
    points,
    bounds,
    zoom,
    options: {
      radius: clusterRadius,
      maxZoom: 9,
      minPoints: clusterCount,
    } as Supercluster.Options<any, any>
  })

  let displayCount = clusters.reduce((sum, cluster) => {
    if (cluster.properties.cluster) return sum + cluster.properties.point_count

    return sum + 1
  }, 0)

  return (
    <Container fluid className='container'>
      <Sidebar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        showNames={showNames}
        setShowNames={setShowNames}
        filters={filters}
        cluster={{
          radius: clusterRadius,
          setRadius: setClusterRadius,
          count: clusterCount,
          setCount: setClusterCount
        }}
        displayCount={displayCount}
        totalCount={points.length}
        zoom={zoom}
        bounds={urlBoundsParam}
      />

      <Col className='right' onClick={() => {
        if (windowWidth < 800) {
          setSidebarOpen(false)
        }
      }}>
        <GoogleMap
          zoom={zoom}
          defaultZoom={mapSettings.defaultZoom}
          defaultCenter={mapSettings.defaultCenter}
          setZoom={setZoom}
          setBounds={setBounds}
          setBoundsParam={setUrlBoundsParam}
          showNames={showNames}
          supercluster={supercluster}
          clusters={clusters}
          points={points}
          updateCourse={updateCourse}
        />
      </Col>
    </Container>
  )
}

function RoutedApp() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />} />
        <Route path='/stats' element={<StatsPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RoutedApp

import { Table } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Row } from "../data/db"
import { useCourses } from "../hooks/use-courses"
import { HOUR_IN_MS, useTime } from "../hooks/use-time"

function count(courses: Row[], now: number) {
  return {
    total: courses.filter(course => course.scheduledOn).length,
    played: courses.filter(course => course.scheduledOn && course.scheduledOn < new Date(now)).length
  }
}

function gbiTop100(courses: Row[], now: number) {
  let all = courses.filter(course => course.rankings?.find(ranking => ranking.level === 2))
  return {
    total: all.filter(course => course.scheduledOn).length,
    played: all.filter(course => course.scheduledOn && course.scheduledOn < new Date(now)).length
  }
}

function worldTop100(courses: Row[], now: number) {
  let all = courses.filter(course => course.rankings?.find(ranking => ranking.level === 1))
  return {
    total: all.filter(course => course.scheduledOn).length,
    played: all.filter(course => course.scheduledOn && course.scheduledOn < new Date(now)).length
  }
}

function doakScores(courses: Row[], now: number) {
  let scores = [5, 6, 7, 8, 9]
  return scores.map(score => {
    let all = courses.filter(course => course.scheduledOn && (course.confidentialGuide?.score ?? 0) >= score)
    return {
      score,
      total: all.length,
      played: all.filter(course => course.scheduledOn && course.scheduledOn < new Date(now)).length
    }
  })
}

export function StatsPage() {
  let [courses] = useCourses()
  let [now] = useTime(HOUR_IN_MS)

  if (!courses.length) {
    return <div>Loading...</div>
  }

  let stats = {
    count: count(courses, now),
    gbiTop100: gbiTop100(courses, now),
    worldTop100: worldTop100(courses, now),
  }

  return (
    <>
    <h1>Stats</h1>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>Stat</th>
          <th>Played</th>
          <th>Planned</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Count</td>
          <td>{stats.count.played}</td>
          <td>{stats.count.total}</td>
        </tr>
        <tr>
          <td>GB{'&'}I Top 100</td>
          <td>{stats.gbiTop100.played}</td>
          <td>{stats.gbiTop100.total}</td>
        </tr>
        <tr>
          <td>World Top 100</td>
          <td>{stats.worldTop100.played}</td>
          <td>{stats.worldTop100.total}</td>
        </tr>

        {doakScores(courses, now).map(doakScore => {
          return <tr key={doakScore.score}>
            <td>Doak {doakScore.score}+</td>
            <td>{doakScore.played}</td>
            <td>{doakScore.total}</td>
          </tr>
        })}
      </tbody>
    </Table>

    <p><Link to='/'>Home</Link></p>
    </>
  )
}
